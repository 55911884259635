import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import formStyle from '../../../assets-soapy/jss/soapy/components/formStyle.jsx'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import withAuthentication from '../../../containers/Authentication/withAuthentication.js'
import withErrorNotification from '../../../containers/withErrorNotification.js'
import GridItem from '../../../components/Grid/GridItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '../../../components/CustomButtons/Button'
import LoadingButton from '../../../components-soapy/LoadingButton'
import TextField from '@material-ui/core/TextField'

const TIME_ZONE = 'timeZone'
const HOUR = 'hour'

const hoursList = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]

const selectWeek = (key) => {
  console.log(key)
}

const EditDateModal = ({
  isClosed,
  timeZoneNames,
  handleTimeChange,
  handleTextChange,
  formFields,
  selectWeekDay,
  onClose,
  weekObject,
  handleCheckboxChange,
  t
}) => {

  return (
    <GridContainer id="content-pane-layout" justify="center">
      {Object.keys(weekObject).map(key =>
        <div onClick={(e) => selectWeekDay(e, key)}>
          <Button color={formFields.weekDays.value.includes(key) ? 'info' : null} variant="contained" disableElevation>
            {weekObject[key]}
          </Button>
        </div>
      )}
      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
        <FormControl
          fullWidth
        >
          <InputLabel
            style={{
              color: '#aaaaaa',
              fontSize: '14px',
              fontWeight: 400
            }}
            htmlFor="simple-select"
          >
            {t('Time zone')}
          </InputLabel>
          <Select
            style={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#495057'
            }}

            inputProps={{
              name: 'simpleSelect'
            }}
            value={formFields[TIME_ZONE].value}
            onChange={event => {
              handleTextChange(event, TIME_ZONE)
            }}
            id="adminEditUnitTimezones"
          >
            {
              timeZoneNames.map(item => {
                return <MenuItem
                  key={item}
                  value={item}
                >
                  {item}
                </MenuItem>
              })
            }
          </Select>
        </FormControl>
      </GridItem>
      <GridItem style={{ minWidth: '40vh', margin: '5px' }}>
        <FormControl
          fullWidth
        >
          <form noValidate>
            <TextField
              value={formFields[HOUR].value || '12:00'}
              style={{ width: '100%' }}
              id="time"
              label="Time"
              type="time"
              defaultValue="12:00"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => handleTimeChange(e)}
              inputProps={{
                step: 600
              }}
            />
          </form>
        </FormControl>
      </GridItem>
      <GridContainer
        justify="center"
        alignItems="center"
        id="card-footer-section"
      >
        <GridItem>
          <LoadingButton
            color="info"
            type="submit"
            id="reportEditSaveButton"
            onClick={(e) => {
              e.preventDefault()
              handleCheckboxChange({ target: { value: true } }, 'active', true)
              onClose()
            }}
            fullWidth
          >
            {t('Apply')}
          </LoadingButton>
        </GridItem>
      </GridContainer>
    </GridContainer>

  )
}

export default withErrorNotification(withAuthentication(withStyles(formStyle)(withTranslation()(EditDateModal))))
